<template lang="pug">
page.lol(:show-header='false' :ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
    //- Content
    template(slot='content')

      page-section.lol-section.lol-section--banner(:fullWidth='true', align='center')
        template(slot='content')
          .banner-img
            img(:src='this.banner')

      //- Main
      page-section#main.lol-section.lol-section--enter.page-pt-md-alt.page-pb-md(align='center')
        template(slot='content')
          //- Loading
          loading-icon.page-mt-lg-alt.page-mb-xxl(v-if='isLoading', size='lg', color='lol-purple')

          //- Ended View
          template(v-else-if='isEndedView')
            h1.h1 Congrats to all who claimed!

            //- Giveaway list
            giveaway-list.pt-2(
              :list='giveawayList',
              :show-prizes='false',
              :show-dates='false',
              :show-state='false',
              :show-action-button='false',
              action-button-variant='lol-purple',
              action-button-title='View Giveaway',
              action-button-margin-top='2',
              action-button-font-size='xs',
              title-size='xs',
              title-align='center',
              tile-size='lg',
              tile-classes='sm:page-px-md',
              :ga-event-category='gaEventCategory',
              :ga-event-label='gaEventLabel'
            )

          //- All other scenarios
          template(v-else)

            //- Error
            template(v-if='isError') Sorry, there was an error, please try refreshing the page.

            //- No Giveaways
            template(v-else-if='!giveawayList.length') Coming Soon

            //- Has Giveaways
            template(v-else)
              h1.giveaway-tile.lol-ff.lol-page-header
                | Amazon Prime Gaming
                br
                | Worlds 22 Celebration Giveaways!
              template(v-if='primaryGiveaway')
                .giveaway-tile.lol-page-subheader.page-mb-sm All eyes are on Worlds 22!
                .giveaway-tile.lol-page-subheader During each Worlds 22 broadcast, we're giving fans a chance to win 150,000 RP! Tune into the livestreams each day for a chance to walk away with all that RP! PLUS you can enter another celebratory giveaway during the entire Worlds 22 timeframe, where 1 lucky winner will walk away with fantastic League of Legends goodies AND 350,000 RP! Enter below and good luck to all!
                .h1.giveaway-tile.pt-5 Primary Giveaway

                giveaway-tile.mx-auto(
                  :giveaway='primaryGiveaway',
                  :show-prizes='false',
                  :show-dates='primaryGiveaway.isEnded?false:true',
                  :show-state='false',
                  :show-action-button='true',
                  action-button-variant='lol-purple',
                  action-button-title='Enter Now',
                  action-button-margin-top='2',
                  action-button-font-size='xs',
                  title-size='lg',
                  tile-size='md'
                  tile-size-sm='lg'
                  title-align='center',
                  date-format='M/D/YYYY h:mm a'
                  :ga-event-category='gaEventCategory',
                  :ga-event-label='gaEventLabel'
                )

              template(v-if='secondHeader')
                .h1.giveaway-tile.pt-5 {{secondHeader}}
                .giveaway-list.d-flex.flex-wrap.justify-content-center.align-items-start
                  giveaway-tile.page-mb-md-alt(
                    v-for='giveaway in activeGiveaways',
                    :list='activeGiveaways',
                    :key='giveaway.idToken',
                    :giveaway='giveaway',
                    :show-prizes='false',
                    :show-dates='true',
                    :show-state='false',
                    :show-action-button='true',
                    title-classes='page-mx-xs',
                    action-button-variant='lol-purple',
                    action-button-title='Enter Now',
                    action-button-margin-top='2',
                    action-button-font-size='xs',
                    title-align='center',
                    title-size='xs',
                    tile-size='sm',
                    date-format='M/D/YYYY h:mm a'
                    :ga-event-category='gaEventCategory',
                    :ga-event-label='gaEventLabel',
                  )

              template(v-if='endedGiveaways.length')
                .h1.giveaway-tile.pt-5 Recently Ended

                .giveaway-list.d-flex.flex-wrap.justify-content-center.align-items-start
                  giveaway-tile.page-mb-md-alt(
                    v-for='giveaway in endedGiveaways',
                    :list='endedGiveaways',
                    :key='giveaway.idToken',
                    :giveaway='giveaway',
                    :show-prizes='false',
                    :show-dates='false',
                    :show-state='false',
                    :show-action-button='true',
                    title-classes='page-mx-xs',
                    action-button-variant='lol-purple',
                    action-button-title='Enter Now',
                    action-button-margin-top='2',
                    action-button-font-size='xs',
                    title-align='center',
                    title-size='xs',
                    tile-size='sm',
                    :ga-event-category='gaEventCategory',
                    :ga-event-label='gaEventLabel',
                  )

    //- Footer
    template(slot='footer')
      base-footer(
        :ga-event-category='gaEventCategory',
        :ga-event-label='gaEventLabel',
        logo-color='black')
</template>
<script>
import GiveawayStartsIn from '../components/GiveawayStartsIn'
import GiveawayEndsIn from '../components/GiveawayEndsIn'
import BaseFooter from '../components/BaseFooter'
import multipleGiveawaysInSyncMixin from '../mixins/base/multiple-giveaways-in-sync'

export default {
  name: 'LOL',
  mixins: [multipleGiveawaysInSyncMixin],
  data() {
    return {
      pageMetaTitle: 'Amazon Prime Gaming Presents: League of Legends Worlds Giveaways',
      primaryToken: 'd0u_beQ',
      primaryGiveaway: null,
      groupsEndGiveaway: null,
      quarterfinalsEndGiveaway: null,
      semifinalsEndGiveaway: null,
      finalsGiveaway: null,
      secondHeader: null,
      giveawayInterval: null,
      activeGiveaways: [],
      endedGiveaways: [],
      groupsIdTokens: ['lE1jCep', 'CPgf_1n', 'GtIAuok', '25YqqQa', 'vHOm2gc', 'ZpMkn1V', '5qm5jzL', '3IIBLtK'],
      quarterFinalsIdTokens: ['k1JGpby', 'AMfjIIT', 'jcrTSev', '4K5hyoh'],
      semiFinalsIdTokens: ['do0EcVL', 'msCkBgg'],
      finalsIdToken: 'Xd-4BRu',
      idTokens: [
        'lE1jCep',
        'CPgf_1n',
        'GtIAuok',
        '25YqqQa',
        'vHOm2gc',
        'ZpMkn1V',
        '5qm5jzL',
        '3IIBLtK',
        'k1JGpby',
        'AMfjIIT',
        'jcrTSev',
        '4K5hyoh',
        'do0EcVL',
        'msCkBgg',
        'Xd-4BRu'
      ],
      banner: require('../assets/themes/amazon-lol/images/banner.png')
    }
  },
  watch: {
    giveawayList() {
      this.activeGiveaways = this.giveawayList.filter((g) => !g.isEnded)
      this.endedGiveaways = this.giveawayList.filter((g) => g.isEnded)
      this.groupsEndGiveaway = this.giveawayList.find(
        (g) => g.idToken === this.groupsIdTokens[this.groupsIdTokens.length - 1]
      )
      this.quarterfinalsEndGiveaway = this.giveawayList.find(
        (g) => g.idToken === this.quarterFinalsIdTokens[this.quarterFinalsIdTokens.length - 1]
      )
      this.semifinalsEndGiveaway = this.giveawayList.find(
        (g) => g.idToken === this.semiFinalsIdTokens[this.semiFinalsIdTokens.length - 1]
      )
      this.finalsGiveaway = this.giveawayList.find((g) => g.idToken === this.finalsIdToken)
    }
  },
  async created() {
    this.primaryGiveaway = await this.fetchGiveawayByIdToken(this.primaryToken)
    this.giveawayInterval = setInterval(this.giveawayStateCheck, 1000)
  },
  beforeDestroy() {
    clearInterval(this.giveawayInterval)
  },
  methods: {
    giveawayStateCheck() {
      const _endedGiveaways = this.giveawayList.filter((g) => g.isEnded)
      let _activeGiveaways

      // Decide to display which second header
      if (this.groupsEndGiveaway && !this.groupsEndGiveaway.isEnded) {
        _activeGiveaways = this.giveawayList.filter((g) => this.groupsIdTokens.includes(g.idToken) && !g.isEnded)
        this.secondHeader = 'Groups Giveaways'
      } else if (this.quarterfinalsEndGiveaway && !this.quarterfinalsEndGiveaway.isEnded) {
        _activeGiveaways = this.giveawayList.filter((g) => this.quarterFinalsIdTokens.includes(g.idToken) && !g.isEnded)
        this.secondHeader = 'Quarterfinals Giveaways'
      } else if (this.semifinalsEndGiveaway && !this.semifinalsEndGiveaway.isEnded) {
        _activeGiveaways = this.giveawayList.filter((g) => this.semiFinalsIdTokens.includes(g.idToken) && !g.isEnded)
        this.secondHeader = 'Semifinals Giveaways'
      } else if (this.finalsGiveaway && !this.finalsGiveaway.isEnded) {
        _activeGiveaways = this.giveawayList.filter((g) => this.finalsIdToken === g.idToken && !g.isEnded)
        this.secondHeader = 'Finals Giveaway'
      } else {
        this.secondHeader = ''
      }

      // Set active/ended lists
      if (_activeGiveaways?.length && _activeGiveaways.length !== this.activeGiveaways.length) {
        this.activeGiveaways = _activeGiveaways
      }
      if (_endedGiveaways?.length && _endedGiveaways.length !== this.endedGiveaways.length) {
        this.endedGiveaways = _endedGiveaways
      }
    }
  },
  components: {
    GiveawayStartsIn,
    GiveawayEndsIn,
    BaseFooter
  }
}
</script>
<style lang="scss" src="../assets/themes/amazon-lol/scss/main.scss" />
